$.validator.defaults.getDataToSend = function (form) {
	return { data: GetJsonData(form) };
};
$.validator.defaults.callbackHandler = function (data, form) {
	if (data.d.Props.Redirect)
		location.href = data.d.Props.Redirect;
	else {
		if (data.d.Status) {
			$(form).find("[type=submit]").parent().remove();
			$($(form).attr("data-selectorok")).show();
			$(form).remove();
		}
		else {
			$(form).find("[type=submit]").html($(form).attr("submit-text"));
			$(form).find("[type=submit]").removeAttr("disabled");
			$($(form).attr("data-selectorko")).show();
		}
	}
}
$.validator.defaults.sendActionsToGTM = function (data, form) {
	if (data.d.Status && data.d.Actions)
		sendActionsToGTM(data.d.Actions);
}
$.validator.defaults.errorPlacement = function (label, element) { }

$.validator.defaults.submitHandler = function () {
	var validator = this,
		form = validator.currentForm;

	$($(form).attr("data-selectorok")).hide();
	$($(form).attr("data-selectorko")).hide();
	$(form).find("[type=submit]").attr("disabled", "disabled");
	if (!$(form).attr("submit-text"))
		$(form).attr("submit-text", $(form).find("[type=submit]").html());
	if (!$(form).find("[type=submit] img").length)
		$(form).find("[type=submit]").html('<img src="/client/dist/images/loading.gif" />');

	$.ajax({
		type: "POST",
		url: $(form).attr('action'),
		cache: false,
		contentType: "application/json; charset=utf-8",
		data: JSON.stringify(validator.settings.getDataToSend(form)),
		dataType: "json",
		success: function (data) {
			try {
				validator.settings.sendActionsToGTM(data, form);
			} catch (ex) { }
			validator.settings.callbackHandler(data, form);
		},
		error: function (data) {
			$(form).find("[type=submit]").html($(form).attr("submit-text"));
			$(form).find("[type=submit]").removeAttr("disabled");
			$(form).find(".result").html('');
			alert("Web-Service Error!!");
		}
	});

	return false;
}

$.validator.defaults.ignore = ':hidden:not(select)';
$.validator.defaults.errorClass = "error";

$.validator.defaults.highlight_base = $.validator.defaults.highlight;
$.validator.defaults.highlight = function (element, errorClass, validClass) {
	element = $(element).parent()
	$.validator.defaults.highlight_base.call(this, element, errorClass, validClass);

	//    var select = $(element).filter('select');
	//    if (select.length && select.data('selectpicker'))
	//        select.data('selectpicker').button.removeClass(validClass).addClass(errorClass);
};

$.validator.defaults.unhighlight_base = $.validator.defaults.unhighlight;
$.validator.defaults.unhighlight = function (element, errorClass, validClass) {
	var select = $(element).filter('select');
	if (select.length)
		element = $(select).parent()
	$.validator.defaults.unhighlight_base.call(this, element, errorClass, validClass);

	//var select = $(element).filter('select');
	//if (select.length && select.data('selectpicker'))
	//    select.data('selectpicker').button.removeClass(errorClass).addClass(validClass);
};

function GetJsonData(formObj) {
	var d = {};
	var form = $(formObj);
	var fields = form.find("input[name!=''][type!=submit][type!=reset]")/*.filter("[type=email], [type=text], [type=password], [type=checkbox], [type=radio], [type=hidden]")*/;

	fields.each(function () {
		var
			type = $(this).attr("type"),
			name = $(this).attr("name"),
			value;

		switch (type) {
			case "checkbox":
			case "radio":
				var list = fields.filter('[name=' + name + '][type=' + type + ']');
				if (list.length == 1)
					value = $(this).is(':checked');
				else {
					if (d[name]) return;

					var values = [];
					list.each(function () { if ($(this).is(':checked')) values.push($(this).val()); });
					value = values.join(',');
				}
				break;
			case "file":
				value = [];
				for (var i = 0; i < this.files.length; i++) { value.push(this.files[i].name); }
				break;
			default:
				value = $(this).val();
				value = $.trim(value);
				break;
		}

		d[name] = value;
	});

	form.find("select[name!=''], textarea[name!='']").each(function () {
		var name = $(this).attr("name");
		d[name] = $(this).val();

	});

	return d;
}

/*
$(function () {
	$('.selectpicker')
	  .on('change', function () { $(this).trigger('click'); })
	  .selectpicker({});
});

$(function () {
	$('.selectpicker')
		.on('change', function () { $(this).trigger('click'); })
		.selectpicker({});
});
*/

function sendActionsToGTM(obj) {
	if (obj == null) return;

	try {
		var actions;
		if (!jQuery.isArray(obj))
			actions = [obj];
		else
			actions = obj;

		for (var i = 0; i < actions.length; i++) {
			dataLayer.push({ "wsActionComplete": actions[i] });
			dataLayer.push({ "event": "Action Complete" });
		}
	} catch (ex) { }
}

function province(selectNazione, select, textbox) {
	var $selectNazione = $(selectNazione);
	if ($selectNazione.length === 0) return;

	var $select = $(select);
	if ($select.length === 0) return;

	var $textbox = $(textbox);
	if ($textbox.length === 0) return;

	var func = function (prov) {
		$select.val('');

		if ($select.data('select2'))
			$select.select2('destroy');


		var trovato = false;

		$select.find('option:gt(0)').remove();
		for (var i = 0; i < prov.length; i++) {
			var p = prov[i],
				v = $textbox.val();
			$select.append($('<option></option>').prop('value', p.Sigla).text(p.Nome));

			if (v) {
				if (v == p.Sigla) {
					$select.val(prov[i].Sigla);
					trovato = true;
				}
			}
		}

		if (!trovato && prov.length !== 0) {
			$textbox.val('');
		}

		if (prov.length > 0) {
			$selectNazione;

			$select.show().trigger("blur");

			if (jQuery.fn.select2) {
				$select.select2(
					{
						closeOnSelect: false,
						width: 'style',
						id: -1,
						dropdownPosition: 'below'
					})
					.on('select2:select', function () {
						$textbox.val($(this).val());
					});
			}

			$textbox.hide();
		} else {
			$selectNazione;
			$select.hide();
			$textbox.show();
		}
	}

	if (!jQuery.fn.select2) {
		$select.on('change', function () {
			$textbox.val($(this).val());
		})
	}

	$selectNazione
		.on('change select2:select', function () {
			if ($(this).val()) {
				$.ajax({
					type: "POST",
					url: "/WS/wsUsers.asmx/Province",
					cache: false,
					contentType: "application/json; charset=utf-8",
					data: JSON.stringify({ nazId: $selectNazione.val() }),
					dataType: "json",
					success: function (data) {
						func(data.d);
					},
					error: function (data) {
						alert("Web-Service Error!!");
					}
				});
			} else {
				func([]);
			}
		});

	if (jQuery.fn.select2)
		$selectNazione.trigger('select2:select');
	else
		$selectNazione.trigger('change');

}

$('form').each(function () {
	province(
		$('select.nazione', this),
		$('select.provincia', this),
		$('input.provincia', this)
	);
});
